import React from 'react';
import uniqolor from 'uniqolor';
import DoughnutChart from '../../charts/DoughnutChart';

// Import utilities
import { tailwindConfig } from '../../utils/Utils';

import { getDashboardProperty } from '../../services/api.service'
import { useQuery } from 'react-query';

function DashboardCard06() {

  const { data, isLoading, error } = useQuery(["topCountry", 1], () => getDashboardProperty('topCountry'), {
    keepPreviousData: true,
    onError: (err) => {
      console.log({ err })
    }
  })


  const getChartData = () => {
    if(!data) return;
    const chartData = {
      labels: data.rows.map(row => row.key),
      datasets: [
        {
          label: 'Top Countries',
          data: data.rows.map(row => parseInt(row.value)),
          backgroundColor: data.rows.map(row=> uniqolor(row.key).color),
          // backgroundColor: [
          //   tailwindConfig().theme.colors.indigo[500],
          //   tailwindConfig().theme.colors.blue[400],
          //   tailwindConfig().theme.colors.indigo[800],
          // ],
          // hoverBackgroundColor: [
          //   tailwindConfig().theme.colors.indigo[600],
          //   tailwindConfig().theme.colors.blue[500],
          //   tailwindConfig().theme.colors.indigo[900],
          // ],
          hoverBorderColor: tailwindConfig().theme.colors.white,
        },
      ],
    };
    return chartData;
  }

 

  return (
    <>
      {!data ? <>
      Loading
      </>
        :
        <div className="flex flex-col col-span-full sm:col-span-6 xl:col-span-4 bg-white shadow-lg rounded-sm border border-slate-200">
          <header className="px-5 py-4 border-b border-slate-100">
            <h2 className="font-semibold text-slate-800">Top Countries</h2>
          </header>
          {/* Chart built with Chart.js 3 */}
          {/* Change the height attribute to adjust the chart height */}
          <DoughnutChart data={getChartData()} width={389} height={260} />
        </div>
      }
    </>);
}

export default DashboardCard06;
