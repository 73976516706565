import React from 'react';
import { useWeb3Context } from '../context/';
import { Link } from 'react-router-dom';

interface ConnectProps {
  connect: (() => Promise<void>) | null;
}
const ConnectButton = ({ connect }: ConnectProps) => {
  return connect ? (
    <button
      className='flex cursor-pointer items-center border px-3 pt-2 pb-1 rounded-md border-btn_color text-btn_color mb-5 bg-gray-500 bg-opacity-10 hover:bg-opacity-20'
      style={{ width: '100%' }}
      onClick={connect}
    >
      <div className='w-fit'>
        <img
          src={
            'https://ik.imagekit.io/h1rxzpffx/blmeta/Group_3zOSuSfIB.png?ik-sdk-version=javascript-1.4.3&updatedAt=1647638399894'
          }
          alt='metamask'
        />
      </div>
      <p className='w-full text-center '>
        <span className='mr-10 font-bold'>Log in using Wallet</span>
      </p>
    </button>
  ) : (
    <button>Loading...</button>
  );
};

interface DisconnectProps {
  disconnect: (() => Promise<void>) | null;
}

const DisconnectButton = ({ disconnect }: DisconnectProps) => {
  return disconnect ? (
    <button onClick={disconnect}>⬅️ Disconnect</button>
  ) : (
    <button>Loading...</button>
  );
};

export function Web3Button() {
  const { web3Provider, connect, disconnect, address } = useWeb3Context();

  return web3Provider ? (
    <>
      <span className='md:text-md text-left text-sm font-light lg:text-lg'>Address</span>
      <span className='md:text-md truncate pl-4 text-right text-sm  font-light lg:text-lg'>
        {address}
      </span>
      <DisconnectButton disconnect={disconnect} /> | &nbsp; 
      <Link to="/">🚀 Dashboard</Link>
    </>
  ) : (
    <ConnectButton connect={connect} />
  );
}
