import React, { useEffect } from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';

import './css/style.scss';

import './charts/ChartjsConfig';

// Import pages
import Dashboard from './pages/Dashboard';
import PageNotFound from './pages/utility/PageNotFound';
import Signin from './pages/Signin';
import { Web3ContextProvider } from './context';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// import Analytics from './pages/Analytics';
// import Fintech from './pages/Fintech';
// import Customers from './pages/ecommerce/Customers';
// import Orders from './pages/ecommerce/Orders';
// import Invoices from './pages/ecommerce/Invoices';
// import Shop from './pages/ecommerce/Shop';
// import Shop2 from './pages/ecommerce/Shop2';
// import Product from './pages/ecommerce/Product';
// import Cart from './pages/ecommerce/Cart';
// import Cart2 from './pages/ecommerce/Cart2';
// import Cart3 from './pages/ecommerce/Cart3';
// import Pay from './pages/ecommerce/Pay';
// import Campaigns from './pages/Campaigns';

// import Feed from './pages/community/Feed';
// import Forum from './pages/community/Forum';
// import ForumPost from './pages/community/ForumPost';
// import Meetups from './pages/community/Meetups';
// import MeetupsPost from './pages/community/MeetupsPost';
// import CreditCards from './pages/finance/CreditCards';
// import Transactions from './pages/finance/Transactions';
// import TransactionDetails from './pages/finance/TransactionDetails';
// import JobListing from './pages/job/JobListing';
// import JobPost from './pages/job/JobPost';
// import CompanyProfile from './pages/job/CompanyProfile';
// import Messages from './pages/Messages';
// import TasksKanban from './pages/tasks/TasksKanban';
// import TasksList from './pages/tasks/TasksList';
// import Inbox from './pages/Inbox';
// import Calendar from './pages/Calendar';
// import Account from './pages/settings/Account';
// import Notifications from './pages/settings/Notifications';
// import Apps from './pages/settings/Apps';
// import Plans from './pages/settings/Plans';
// import Billing from './pages/settings/Billing';
// import Feedback from './pages/settings/Feedback';
// import Changelog from './pages/utility/Changelog';
// import Roadmap from './pages/utility/Roadmap';
// import Faqs from './pages/utility/Faqs';
// import EmptyState from './pages/utility/EmptyState';
// import KnowledgeBase from './pages/utility/KnowledgeBase';
// import Signin from './pages/Signin';
// import Signup from './pages/Signup';
// import ResetPassword from './pages/ResetPassword';
// import Onboarding01 from './pages/Onboarding01';
// import Onboarding02 from './pages/Onboarding02';
// import Onboarding03 from './pages/Onboarding03';
// import Onboarding04 from './pages/Onboarding04';
// import ButtonPage from './pages/component/ButtonPage';
// import FormPage from './pages/component/FormPage';
// import DropdownPage from './pages/component/DropdownPage';
// import AlertPage from './pages/component/AlertPage';
// import ModalPage from './pages/component/ModalPage';
// import PaginationPage from './pages/component/PaginationPage';
// import TabsPage from './pages/component/TabsPage';
// import BreadcrumbPage from './pages/component/BreadcrumbPage';
// import BadgePage from './pages/component/BadgePage';
// import AvatarPage from './pages/component/AvatarPage';
// import TooltipPage from './pages/component/TooltipPage';
// import AccordionPage from './pages/component/AccordionPage';
// import IconsPage from './pages/component/IconsPage';

import { ReactQueryDevtools } from 'react-query/devtools';
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from 'react-query';

const NftList = React.lazy(() => import('./pages/marketplace/NftList'));
const NftImport = React.lazy(() => import('./pages/marketplace/NftImport'));
const NftAdd = React.lazy(() => import('./pages/marketplace/NftAdd'));

const PlatformConfig = React.lazy(() => import('./pages/settings/PlatformConfig'));

const BreadcrumbPage = React.lazy(() => import('./pages/component/BreadcrumbPage'));
const BadgePage = React.lazy(() => import('./pages/component/BadgePage'));
const Product = React.lazy(() => import('./pages/ecommerce/Product'));

const Cart = React.lazy(() => import('./pages/ecommerce/Cart'));
const Cart2 = React.lazy(() => import('./pages/ecommerce/Cart2'));
const Cart3 = React.lazy(() => import('./pages/ecommerce/Cart3'));

const UsersList = React.lazy(() => import('./pages/community/UsersTabs'));
const UsersTiles = React.lazy(() => import('./pages/community/UsersTiles'));
const Campaigns = React.lazy(() => import('./pages/Campaigns'));
const Profile = React.lazy(() => import('./pages/community/Profile'));
const ModalPage = React.lazy(() => import('./pages/component/ModalPage'));
const AlertPage = React.lazy(() => import('./pages/component/AlertPage'));
const FormPage = React.lazy(() => import('./pages/component/FormPage'));
const PaginationPage = React.lazy(() => import('./pages/component/PaginationPage'));
const TabsPage = React.lazy(() => import('./pages/component/TabsPage'));
const Fintech = React.lazy(() => import('./pages/Fintech'));

const Transactions = React.lazy(() => import('./pages/activity/Transactions'));
const TransactionsDetails = React.lazy(() => import('./pages/finance/TransactionDetails'));

const queryClient = new QueryClient();



function App() {
  const location = useLocation();

  const isLoggedIn = localStorage.getItem("authToken") && localStorage.getItem("authToken").length > 0;

  useEffect(() => {
    document.querySelector('html').style.scrollBehavior = 'auto';
    window.scroll({ top: 0 });
    document.querySelector('html').style.scrollBehavior = '';
  }, [location.pathname]); // triggered on route change

  if(!isLoggedIn && location.pathname !== "/login"){
    return <Navigate to="/login" />;
  }

  return (
    <>
      <Web3ContextProvider>
        <QueryClientProvider client={queryClient}>
          <Routes>
            <Route exact path='/' element={<Dashboard />} />
            <Route path='/login' element={<Signin />} />
            <Route
              path='/settings/config'
              element={
                <React.Suspense fallback={<>...</>}>
                  <PlatformConfig />
                </React.Suspense>
              }
            />
            <Route
              path='/marketplace/nfts/add'
              element={
                <React.Suspense fallback={<>...</>}>
                  <NftAdd />
                </React.Suspense>
              }
            />
            <Route
              path='/marketplace/nfts/import'
              element={
                <React.Suspense fallback={<>...</>}>
                  <NftImport />
                </React.Suspense>
              }
            />
            <Route
              path='/marketplace/nfts'
              element={
                <React.Suspense fallback={<>...</>}>
                  <NftList />
                </React.Suspense>
              }
            />
            <Route
              path='/ecommerce/product'
              element={
                <React.Suspense fallback={<>...</>}>
                  <Product />
                </React.Suspense>
              }
            />

            <Route
              path='/ecommerce/cart'
              element={
                <React.Suspense fallback={<>...</>}>
                  <Cart />
                </React.Suspense>
              }
            />
            <Route
              path='/ecommerce/cart-2'
              element={
                <React.Suspense fallback={<>...</>}>
                  <Cart2 />
                </React.Suspense>
              }
            />
            <Route
              path='/ecommerce/cart-3'
              element={
                <React.Suspense fallback={<>...</>}>
                  <Cart3 />
                </React.Suspense>
              }
            />

            <Route
              path='/community/users'
              element={
                <React.Suspense fallback={<>...</>}>
                  <UsersList />
                </React.Suspense>
              }
            />
            <Route
              path='/community/users-tiles'
              element={
                <React.Suspense fallback={<>...</>}>
                  <UsersTiles />
                </React.Suspense>
              }
            />
            <Route
              path='/community/profile'
              element={
                <React.Suspense fallback={<>...</>}>
                  <Profile />
                </React.Suspense>
              }
            />
            <Route
              path='/dashboard/fintech'
              element={
                <React.Suspense fallback={<>...</>}>
                  <Fintech />
                </React.Suspense>
              }
            />
            {/* <Route path="/dashboard/analytics" element={<Analytics />} />
         
          <Route path="/ecommerce/customers" element={<Customers />} />
          <Route path="/ecommerce/orders" element={<Orders />} />
          <Route path="/ecommerce/invoices" element={<Invoices />} />
          <Route path="/ecommerce/shop" element={<Shop />} />
          <Route path="/ecommerce/shop-2" element={<Shop2 />} />
          
          <Route path="/ecommerce/cart" element={<Cart />} />
          <Route path="/ecommerce/cart-2" element={<Cart2 />} />
          <Route path="/ecommerce/cart-3" element={<Cart3 />} />
          <Route path="/ecommerce/pay" element={<Pay />} />
          <Route path="/campaigns" element={<Campaigns />} />
          <Route path="/community/users-tabs" element={<UsersTabs />} />
          <Route path="/community/users-tiles" element={<UsersTiles />} />
          <Route path="/community/profile" element={<Profile />} />
          <Route path="/community/feed" element={<Feed />} />
          <Route path="/community/forum" element={<Forum />} />
          <Route path="/community/forum-post" element={<ForumPost />} />
          <Route path="/community/meetups" element={<Meetups />} />
          <Route path="/community/meetups-post" element={<MeetupsPost />} />
          <Route path="/finance/cards" element={<CreditCards />} />
          <Route path="/finance/transactions" element={<Transactions />} />
          <Route path="/finance/transaction-details" element={<TransactionDetails />} />
          <Route path="/job/job-listing" element={<JobListing />} />
          <Route path="/job/job-post" element={<JobPost />} />
          <Route path="/job/company-profile" element={<CompanyProfile />} />
          <Route path="/messages" element={<Messages />} />
          <Route path="/tasks/kanban" element={<TasksKanban />} />
          <Route path="/tasks/list" element={<TasksList />} />
          <Route path="/inbox" element={<Inbox />} />
          <Route path="/calendar" element={<Calendar />} />
          <Route path="/settings/account" element={<Account />} />
          <Route path="/settings/notifications" element={<Notifications />} />
          <Route path="/settings/apps" element={<Apps />} />
          <Route path="/settings/plans" element={<Plans />} />
          <Route path="/settings/billing" element={<Billing />} />
          <Route path="/settings/feedback" element={<Feedback />} />
          <Route path="/utility/changelog" element={<Changelog />} />
          <Route path="/utility/roadmap" element={<Roadmap />} />
          <Route path="/utility/faqs" element={<Faqs />} />
          <Route path="/utility/empty-state" element={<EmptyState />} />
          <Route path="/utility/404" element={<PageNotFound />} />
          <Route path="/utility/knowledge-base" element={<KnowledgeBase />} />
          <Route path="/signin" element={<Signin />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/onboarding-01" element={<Onboarding01 />} />
          <Route path="/onboarding-02" element={<Onboarding02 />} />
          <Route path="/onboarding-03" element={<Onboarding03 />} />
          <Route path="/onboarding-04" element={<Onboarding04 />} />
          <Route path="/component/button" element={<ButtonPage />} />
          <Route path="/component/form" element={<FormPage />} />
          <Route path="/component/dropdown" element={<DropdownPage />} />

          <Route path="/component/pagination" element={<PaginationPage />} />
          <Route path="/component/tabs" element={<TabsPage />} />
          <Route path="/component/avatar" element={<AvatarPage />} />
          <Route path="/component/tooltip" element={<TooltipPage />} />
          <Route path="/component/accordion" element={<AccordionPage />} />
          <Route path="/component/icons" element={<IconsPage />} /> */}

            <Route
              path='/activity/transactions'
              element={
                <React.Suspense fallback={<>...</>}>
                  <Transactions />
                </React.Suspense>
              }
            />
            <Route
              path='/activity/transaction-details'
              element={
                <React.Suspense fallback={<>...</>}>
                  <TransactionsDetails />
                </React.Suspense>
              }
            />

            <Route
              path='/component/tabs'
              element={
                <React.Suspense fallback={<>...</>}>
                  <TabsPage />
                </React.Suspense>
              }
            />
            <Route
              path='/campaigns'
              element={
                <React.Suspense fallback={<>...</>}>
                  <Campaigns />
                </React.Suspense>
              }
            />
            <Route
              path='/component/pagination'
              element={
                <React.Suspense fallback={<>...</>}>
                  <PaginationPage />
                </React.Suspense>
              }
            />
            <Route
              path='/component/form'
              element={
                <React.Suspense fallback={<>...</>}>
                  <FormPage />
                </React.Suspense>
              }
            />
            <Route
              path='/component/alert'
              element={
                <React.Suspense fallback={<>...</>}>
                  <AlertPage />
                </React.Suspense>
              }
            />
            <Route
              path='/component/modal'
              element={
                <React.Suspense fallback={<>...</>}>
                  <ModalPage />
                </React.Suspense>
              }
            />
            <Route
              path='/component/badge'
              element={
                <React.Suspense fallback={<>...</>}>
                  <BadgePage />
                </React.Suspense>
              }
            />
            <Route
              path='/component/breadcrumb'
              element={
                <React.Suspense fallback={<>...</>}>
                  <BreadcrumbPage />
                </React.Suspense>
              }
            />
            <Route path='*' element={<PageNotFound />} />
          </Routes>
          {/* <ReactQueryDevtools initialIsOpen={false} /> */}
        </QueryClientProvider>
        <ToastContainer
          hideProgressBar
          position="bottom-right"
          autoClose={2000}
        />
      </Web3ContextProvider>
    </>
  );
}

export default App;
