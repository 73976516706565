import React from 'react';

const NftItem = (props) => {
  return (
    <li className="flex px-2">

      <div className="w-9 h-9 rounded-full shrink-0 bg-rose-500 my-2 mr-3">
        <div className="flex items-center">
          <div className="w-10 h-10 shrink-0 mr-2 sm:mr-3">
            <img className="rounded-full" src={props.image} width="40" height="40" alt={props.title} />
          </div>

        </div>
      </div>

      <div className="grow flex items-center border-b border-slate-100 text-sm py-2">
        <div className="grow flex justify-between">
          <div className="self-center">
            <a className="font-medium text-slate-800 hover:text-slate-900" href="#0">
              <div className="font-medium text-slate-800">
                {props.title}<br />
                <small className='text-slate-300'>{props.owner}</small>
              </div>
            </a>
          </div>
          <div className="shrink-0 self-start ml-2">
            <span className="font-medium text-slate-800">{props.views}</span>
          </div>
        </div>
      </div>
    </li>
  );
}

function DashboardCard12() {
  return (
    <div className="col-span-full xl:col-span-6 bg-white shadow-lg rounded-sm border border-slate-200">
      <header className="px-5 py-4 border-b border-slate-100">
        <h2 className="font-semibold text-slate-800">Top NFTs</h2>
        <small>By Views</small>
      </header>
      <div className="p-3">

        <div>
          <header className="flex text-xs uppercase text-slate-400 bg-slate-50 rounded-sm font-semibold p-2 justify-between">
            <span>NFTs</span>
            <span>Views</span>
          </header>
          <ul className="my-1">
            {
              [
                {
                  id: 1,
                  title: '50 Cent #8',
                  image: 'https://dejsotk94siw4.cloudfront.net/rocketz/QmeK8sUM19g3nMtw6ETpuN6tvsES5zaMH26pyodLSChLs8',
                  owner: '0x30587A99dFFF0F6A25c1539AE090de0be259045a',
                  views: '50,013'
                },
                {
                  id: 2,
                  title: '50 Cent #9',
                  image: 'https://dejsotk94siw4.cloudfront.net/rocketz/QmeK8sUM19g3nMtw6ETpuN6tvsES5zaMH26pyodLSChLs8',
                  owner: '0x30587A99dFFF0F6A25c1539AE090de0be259045a',
                  views: '10,283'
                },
                {
                  id: 3,
                  title: '50 Cent #10',
                  image: 'https://dejsotk94siw4.cloudfront.net/rocketz/QmeK8sUM19g3nMtw6ETpuN6tvsES5zaMH26pyodLSChLs8',
                  owner: '0x30587A99dFFF0F6A25c1539AE090de0be259045a',
                  views:'5,023'
                },
                {
                  id: 2,
                  title: '50 Cent #19',
                  image: 'https://dejsotk94siw4.cloudfront.net/rocketz/QmeK8sUM19g3nMtw6ETpuN6tvsES5zaMH26pyodLSChLs8',
                  owner: '0x30587A99dFFF0F6A25c1539AE090de0be259045a',
                  views: '234'
                },
              ].map(item => (<NftItem
                {...item}
              />))
            }
          </ul>
        </div>

      </div>
    </div>
  );
}

export default DashboardCard12;
