import axios from 'axios'
import { create } from 'ipfs-http-client'
import { Buffer } from 'buffer';
import * as CONFIG from '../Config'

export async function getDashboardProperty(propertyName, pageNumber = 1) {
    const authToken = localStorage.getItem("authToken");
    const data = await fetch(`${CONFIG.BASE_URL}/_analytics/${propertyName}?page=${pageNumber}`,{
        headers: {
            Authorization: `Bearer ${authToken}`
        }
    }).then(res => res.json())
    return data?.data
}

export async function getLatestVoucherNumber() {
    const authToken = localStorage.getItem("authToken");
    const data = await fetch(`${CONFIG.BASE_URL}/_admin/latest-voucher`,{
        headers: {
            Authorization: `Bearer ${authToken}`
        }
    }).then(res => res.json())
    return data?.data
}

export async function getNfts(pageNumber = 1, byUser = null) {
    let reqUrl = `${CONFIG.BASE_URL}/marketplace?page=${pageNumber}&include=unlisted`
    if(byUser) reqUrl += `&user=${byUser}`
    const data = await fetch(reqUrl).then(res => res.json())
    if (!data.success) throw new Error('Unknown Error')
    console.log('API response:', data.data)
    return data.data
}

export async function getUsers(pageNumber = 1) {
    const authToken = localStorage.getItem("authToken");
    const data = await fetch(`${CONFIG.BASE_URL}/_admin/users?page=${pageNumber}`,{
        headers: {
            Authorization: `Bearer ${authToken}`
        }
    }).then(res => res.json())
    if (!data.success) throw new Error('Unknown Error')
    console.log('API response:', data.data?.results)
    return data.data?.results
}
export async function getTxnHistory(pageNumber = 1) {
    const authToken = localStorage.getItem("authToken");
    const data = await fetch(`${CONFIG.BASE_URL}/_admin/history?page=${pageNumber}`,{
        headers: {
            Authorization: `Bearer ${authToken}`
        }
    }).then(res => res.json())
    if (!data.success) throw new Error('Unknown Error')
    console.log('API response:', data.data?.results)
    return data.data?.results
}

export async function analyseNft(nftId){
    const authToken = localStorage.getItem("authToken");
    const data = await axios.post(`${CONFIG.BASE_URL}/_admin/nft`,{
        action:'ANALYSE',
        data:{
            nftId
        }
    },{
        headers: {
            Authorization: `Bearer ${authToken}`
        }
    }).then(res => res.data)
    console.log({data})
    // console.error('API Error:', data.message)
    if (!data.success) throw new Error(data?.message ?? 'Unknown Error')
    return data?.data
}

export async function removeNft(nftId){
    const authToken = localStorage.getItem("authToken");
    const data = await axios.post(`${CONFIG.BASE_URL}/_admin/nft`,{
        action:'REMOVE',
        data:{
            nftId
        }
    },{
        headers: {
            Authorization: `Bearer ${authToken}`
        }
    }).then(res => res.data)
    console.log({data})
    console.error('API Error:', data.message)
    if (!data.success) throw new Error(data?.message ?? 'Unknown Error')
    return data?.data
}

export async function enableListing(nftId){
    const authToken = localStorage.getItem("authToken");
    const data = await axios.post(`${CONFIG.BASE_URL}/_admin/nft`,{
        action:'ENABLE_LISTING',
        data:{
            nftId
        }
    },{
        headers: {
            Authorization: `Bearer ${authToken}`
        }
    }).then(res => res.data)
    if (!data.success) throw new Error(data?.message ?? 'Unknown Error')
    return data?.data
}

export async function updatePlatformConfig(postBody){
    const authToken = localStorage.getItem("authToken");
    const data = await axios.post(`${CONFIG.BASE_URL}/_admin/config`,postBody,{
        headers: {
            Authorization: `Bearer ${authToken}`
        }
    }).then(res => res.data)
    console.log({data})
    if (!data.success) throw new Error(data?.message ?? 'Unknown Error')
    return data
}

export async function createImportNftTask(postBody){
    const authToken = localStorage.getItem("authToken");
    const data = await axios.post(`${CONFIG.BASE_URL}/_admin/job-queue`,postBody,{
        headers: {
            Authorization: `Bearer ${authToken}`
        }
    }).then(res => res.data)
    console.log({data})
    if (!data.success) throw new Error(data?.message ?? 'Unknown Error')
    return data
}

export async function UploadToIPFS(file, fileName){
    const projectId = CONFIG.IPFS_SERVICE.projectId;
    const projectSecret =CONFIG.IPFS_SERVICE.projectSecret;
    const infuraEndpoint = new URL(CONFIG.IPFS_SERVICE.endPoint)
    const auth =
        'Basic ' + Buffer.from(projectId + ':' + projectSecret).toString('base64')
    let objectData = {
        host: infuraEndpoint.hostname,
        port: infuraEndpoint.port,
        protocol: 'https',
        headers: {
            authorization: auth
        }
    }
    const client = create(fileName !== 'image' ? { ...objectData, ContentType: "application/json" } : objectData)
    let uploadFileJson = fileName !== 'image' ? JSON.stringify(file) : file

    let handleAwait = await client.add(uploadFileJson).then((res) => {
        return `${res.path}`

    }).catch(err => {
        throw err
    })
    return handleAwait
}

export async function LazyMintNft(postBody){
    const authToken = localStorage.getItem("authToken");
    const data = await axios.post(`${CONFIG.BASE_URL}/_admin/nft`,{
        action:'LAZY_MINT',
        data:{
            ...postBody
        }
    },{
        headers: {
            Authorization: `Bearer ${authToken}`
        }
    }).then(res => res.data)
    if (!data.success) throw new Error(data?.message ?? 'Unknown Error')
    return data?.data
}

export const auth = {
    getLoginMessage: async (address) => {
        const loginMessage = await axios.get(`${CONFIG.BASE_URL}/auth/message?address=${address}`)
        return loginMessage.data.message
    },
    login: async (address, signedMessage) => {
        console.log({ address, signedMessage })
        const login = await axios.post(
            `${CONFIG.BASE_URL}/auth/login`,
            {
                address,
                signature: signedMessage
            },
        );
        return login.data
    }
}

export default {
    getNfts
}